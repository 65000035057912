:root {
  --primary-ff: "Poppins", sans-serif;
  --primary-fs: 1rem;
  --primary-padding: 1rem;
  --color-white: #fff;
  --color-bb: #dadce0;
  --color-saffron: #E8481D;

  /* panel colors */
  --color-panel-light: #f0eff5;
  --color-panel-grey-light: #86848c;
  --color-panel-dark-grey: #56555e;
  --color-panel-dark: #27282f;
  --color-panel-primary: rgb(71, 7, 234);
  --color-panel-success: rgb(34, 202, 75);
  --color-panel-danger: rgb(255, 67, 54);
  --color-panel-warning: rgb(234, 181, 7);
  --color-panel-purple: rgb(160, 99, 254);

  --color-panel-primary-light: rgba(71, 7, 234, 0.2);
  --color-panel-success-light: rgba(34, 202, 75, 0.2);
  --color-panel-danger-light: rgba(255, 67, 54, 0.2);
  --color-panel-purple-light: rgba (160, 99, 245, 0.2);
  /* non auth pages */
  /* dashboard */
  --dash-header-height: 3.5rem;
  --sidebar-width: 15.5rem;

  /* for admin */
  --admin-primary-green: rgb(23, 172, 122);
  --admin-primary-green-hover: rgb(16, 142, 100);
  /************************************************************/

  --clr-primary-bg: hsl(30, 21.1%, 92.5%);
  --clr-accent-500: hsl(12, 60%, 45%);
  --clr-accent-400: hsl(12, 88%, 59%);
  --clr-accent-300: hsl(12, 88%, 75%);
  --clr-accent-100: hsl(13, 100%, 96%);

  --clr-primary-400: hsl(228, 39%, 23%);

  --clr-neutral-900: hsl(232, 12%, 13%);
  --clr-neutral-400: hsl(0, 0%, 71%);
  --clr-neutral-100: hsl(0 0% 100%);


  --ff-primary: 'Noto Serif', serif;
  --ff-secondary: 'Lato', sans-serif;
  --ff-nav: 'Lato', sans-serif;
  --ff-button: 'Lato', sans-serif;



  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 700;

  --fs-300: 0.8125rem;
  --fs-400: 0.875rem;
  --fs-500: 0.9375rem;
  --fs-600: 1rem;
  --fs-700: 1.875rem;
  --fs-800: 2.5rem;
  --fs-900: 3.5rem;

  --fs-body: var(--fs-400);
  --fs-primary-heading: var(--fs-800);
  --fs-secondary-heading: var(--fs-700);
  --fs-nav: var(--fs-500);
  --fs-button: var(--fs-300);

  --size-rootheader: 4.375rem;
  --size-container: 90em;
  --size-100: 0.25rem;
  --size-200: 0.5rem;
  --size-300: 0.75rem;
  --size-400: 1rem;
  --size-500: 1.5rem;
  --size-600: 2rem;
  --size-700: 3rem;
  --size-800: 4rem;
  --size-900: 5rem;


}



/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
  margin: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100%;
  text-rendering: optimizeSpeed;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 0.5em;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 100vw;
  margin-block: 0.2em;
}

::-webkit-scrollbar-thumb {
  background: #babac0;
  border-radius: 100vw;
  border: 2px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-panel-dark-grey);
}

::-webkit-scrollbar-button {
  display: none;
}

.test-colors {
  background: rgb(218, 0, 0);
}